// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/addressBook/_icon_contact list_edit.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/addressBook/_icon_contact list_phone.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".address-book .edit-button{margin-right:.26667rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.address-book .dial-button,.address-book .edit-button{display:inline-block;width:.64rem;height:.64rem;border:0}.address-book .dial-button{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:contain}.address-book .delete-button{height:100%}.address-book .btn-add{width:100%;height:1.06667rem;border-radius:.53333rem}.address-book .concat-content{width:9.06667rem;border-radius:.21333rem}.address-book .van-contact-edit,.address-book .van-popup--center{border-radius:.21333rem}.address-book .concat-content{padding:.21333rem}.address-book .van-cell{border-bottom:0}.address-book .van-contact-list__bottom{background-color:transparent}.address-book .van-swipe-cell{width:9.22667rem;margin-bottom:.26667rem;border-radius:.10667rem;background-color:#fff}.address-book .van-swipe-cell__right{top:0}.address-book .van-popup__close-icon--top-right{top:.10667rem;right:.10667rem}.address-book .van-button--danger{background-color:#ed7171;border:.02667rem solid #ed7171}", ""]);
// Exports
module.exports = exports;
