<template>
    <div class="address-book">
        <van-search  v-model="listQuery.keyword" placeholder="请输入名称/手机号码" shape="round" background="transparent"  @search="onSearch" input-align="center"/>
        <van-swipe-cell v-for="(item, index) in list" :key="index" v-if="!isNoData">
            <div class="concat-person">
                <div class="left">
                    <div class="img">
                        <img src="../../assets/images/addressBook/icon_list_staff.png"/>
                    </div>
                    <div class="content">
                        <p class="name">{{item.name}}</p>
                        <p class="tel">{{item.phone}}</p>
                    </div>
                </div>
                <div class="btn-container">
                    <span class="edit-button" @click="onEdit(item.name, item.phone, item.id)"></span>
                    <a :href="'tel:' + item.phone"class="dial-button" @click="dialNumber(item.phone)"></a>
                </div>
            </div>
            <template #right>
                <van-button square text="删除" type="danger" class="delete-button" @click="deleteContact(item.id)"/>
            </template>
        </van-swipe-cell>
        <div class="no-data" v-if="isNoData">
            <div>
                <img src="../../assets/images/icon_nodata.png"/>
            </div>
            <div>
                <span class="no-data-text">暂无数据</span>
            </div>
        </div>
        <div class="van-contact-list__bottom">
            <div class="btn-add" @click="onAdd">+</div>
        </div>
    </div>
</template>

<script>
import {listPagePhone, addPhone, modifyPhone, deletePhone} from '@/api/addressBook';
export default {
    data() {
        return {
            isNoData: false,
            showPopup: false,//新增联系人弹窗是否显示
            searchPerson: '',//搜索条件
            isEdit: false,//是否编辑联系人
            chosenContactId: '1',
            editingContact: {
                name: '',
                phone: '',
                id: ''
            },
            list: [],
            listQuery: {
                keyword: '',
                pageIndex: 1,
                pageSize: 200
            }
        }
    },
    mounted() {
        this.listPagePhone();
    },
    methods: {
        /** 查询所有联系人 */
        listPagePhone() {
            listPagePhone(this.listQuery).then(res => {
                if (res.code == 200) {
                    this.list = res.data || [];
                    this.isNoData = this.list.length > 0 ? false : true;
                }
            })
        },
        /** 新增 */
        onAdd() {
            this.editingContact.name = '';
            this.editingContact.phone = '';
            this.editingContact.id = '';
            this.isEdit = false;
            this.$router.push({name: 'addAdddressBook', params: this.editingContact});
            // this.showPopup = true;
        },
        /** 编辑 */
        onEdit(name, tel, id) {
            this.editingContact.name = name;
            this.editingContact.phone = tel;
            this.editingContact.id = id;
            this.isEdit = true;
            this.$router.push({name: 'addAdddressBook', params: this.editingContact});
        },
        /** 拨打电话 */
        dialNumber() {

        },
        /** 搜索 */
        onSearch(val) {
            this.listPagePhone();
        },
        /** 删除 */ 
        deleteContact(id) {
            this.$dialog.confirm({
                title: '提示',
                message: '确定删除该联系人?',
            })
            .then(() => {
                deletePhone({id: id}).then(res => {
                    if (res.code == 200) {
                        this.listPagePhone();
                        this.$notify({ type: 'success', message: '删除成功' });
                    }
                })
                
            })
            .catch(() => {
                // on cancel
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .address-book {
        width: 347px;
        margin: 0 auto;
        padding: 14px;
        .concat-person {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            .left {
                display: flex;
                align-items: center;
                height: 50px;
                .img {
                    width: 38px;
                    
                    margin-right: 12px;
                    border-radius: 50%;
                    img {
                        width: 38px;
                        height: 38px;
                        vertical-align: middle;
                        border-radius: 50%;
                    }
                }
                .content {
                    .name {
                        font-size: 14px;
                        color: #4F4F4F;
                    }
                    .tel {
                        font-size: 12px;
                        color: #438EFD;
                    }
                }
            }
            
            &::after {
                border-bottom: 0;
            }
            .btn-container {
                display: inline-flex;
            }
        }
        .van-contact-list__bottom {
            // bottom: 70px;
            .btn-add {
                position: fixed;
                bottom: 70px;
                right: 10px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 26px;
                color: #fff;
                text-align: center;
                line-height: 40px;
                background-color: #bfd9f6;
            }
        }
    }
</style>
<style lang="scss">
    .address-book {
        .edit-button {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            border: 0;
            background: url('../../assets/images/addressBook/_icon_contact list_edit.png');
            background-size: contain;
        }
        .dial-button {
            display: inline-block;
            width: 24px;
            height: 24px;
            border: 0;
            background: url('../../assets/images/addressBook/_icon_contact list_phone.png');
            background-size: contain;
        }
        .delete-button {
            height: 100%;
            /* position: absolute;
            top: 0;
            width: 58px;
            padding: 0;  */
        } 
        .btn-add {
            width: 100%;
            height: 40px;
            border-radius: 20px;
        }
        .concat-content {
            width: 340px;
            border-radius: 8px;
        }
        .van-contact-edit,
        .van-popup--center {
            border-radius: 8px;
        }
        .concat-content {
            padding: 8px;
        }
        .van-cell {
            border-bottom: 0;
        }
        .van-contact-list__bottom {
            background-color: transparent;
        }
        .van-swipe-cell {
            width: 346px;
            margin-bottom: 10px;
            // padding: 0 8px;
            border-radius: 4px;
            background-color: #fff;
        }
        .van-swipe-cell__right {
            top: 0;
            // right: -9px;
        }
        .van-popup__close-icon--top-right {
            top: 4px;
            right: 4px;
        }
        .van-button--danger {
            background-color: #ED7171;
            border: 1px solid #ED7171;
        }
    }
</style>